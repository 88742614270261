<template>
  <div class="data-box">
    <van-nav-bar
        title="对接要求"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-head">
      组织者对您有以下对接要求，请提前准备
    </div>
    <div class="data-box-body">
      <div class="body-item" v-if="$store.getters.getOPDetails.relateType === 0 || $store.getters.getOPDetails.relateType === 2">
        <div class="item-title align-center">
          车辆对接检查项
        </div>
        <div class="item-check">
          <div class="check-item" v-for="item in personCheckList" :key="item.id" v-show="item.isCheck">
            <i class="iconfont icon-tongguo"></i>
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
      <div class="body-item" v-if="$store.getters.getOPDetails.relateType === 1 || $store.getters.getOPDetails.relateType === 2">
        <div class="item-title align-center">
          人员对接检查项
        </div>
        <div class="item-check">
          <div class="check-item" v-for="item in carCheckList" :key="item.id" v-show="item.isCheck">
            <i class="iconfont icon-tongguo"></i>
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
      <div class="body-item">
        <div class="item-title">
          工作时间
        </div>
        <div class="item-work-time">
          <div class="work-time-icon">
            <i class="iconfont icon-qiyeshezhi i-left"></i>
            <div class="icon-line"></div>
            <i class="iconfont icon-qiyeshezhi i-right"></i>
          </div>
          <div class="work-time-div">
            <div class="div-time">{{$store.getters.getOPDetails.takeStartDate}}</div>
          </div>
          <div class="work-time-div">
            <div class="div-time">{{$store.getters.getOPDetails.takeEndDate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "parCheck",
  data() {
    return {
      personCheckList: [
        {id: 0, isCheck: false, name: "身份相符"},
        {id: 1, isCheck: false, name: "身体健康"},
        {id: 2, isCheck: false, name: "统一着装"},
        {id: 3, isCheck: false, name: "身份证件"},
        {id: 4, isCheck: false, name: "驾驶证件"},
        {id: 5, isCheck: false, name: "工作证件"},
        {id: 6, isCheck: false, name: "通讯畅通"},
        {id: 7, isCheck: false, name: "手机耳机"},
        {id: 8, isCheck: false, name: "随行人员"},
        {id: 9, isCheck: false, name: "系统登记"},
        {id: 10, isCheck: false, name: "证件拍照"},
        {id: 11, isCheck: false, name: "人员拍照"}
      ],
      carCheckList: [
        {id: 0, isCheck: false, name: "车辆外观"},
        {id: 1, isCheck: false, name: "车辆内饰"},
        {id: 2, isCheck: false, name: "车况良好"},
        {id: 3, isCheck: false, name: "空调正常"},
        {id: 4, isCheck: false, name: "皮革座椅"},
        {id: 5, isCheck: false, name: "车厢异味"},
        {id: 6, isCheck: false, name: "车内地毯"},
        {id: 7, isCheck: false, name: "尾箱清空"},
        {id: 8, isCheck: false, name: "油电剩余"},
        {id: 9, isCheck: false, name: "里程表数"},
        {id: 10, isCheck: false, name: "系统登记"},
        {id: 11, isCheck: false, name: "车辆牌照"}
      ]
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let activitySets = that.$store.getters.getOPDetails.activitySets;
      activitySets.forEach(item => {
        if(item.relateType === 0){
          item.checkTypeNumArticle.split(",").forEach(item => {
            that.personCheckList[item].isCheck = true;
          })
        }
        if(item.relateType === 1){
          item.checkTypeNumArticle.split(",").forEach(item => {
            that.carCheckList[item].isCheck = true;
          })
        }
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.body-item{
  width: 80%;
  margin: 10px auto;
  padding: 10px 5%;
  background-color: #FFFFFF;
  border-radius: 10px;
  .item-title{
    width: 100%;
    color: #3E4576;
    font-size: 16px;
    font-weight: bold;
  }
  .item-check{
    width: 100%;
    margin: 6px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    .check-item{
      width: 33.3%;
      i{
        color: rgb(90, 187, 43);
        font-size: 14px;
      }
      span{
        margin-left: 10px;
        color: #3E4576;
        font-size: 14px;
      }
    }
  }
  .item-work-time{
    width: 100%;
    margin: 16px auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .work-time-icon{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .i-left{
        color: #3476FF;
        font-size: 20px;
      }
      .icon-line{
        width: 150px;
        height: 2px;
        margin: 0px 4px;
        border-top: 2px dashed #8B8FAD;
      }
      .i-right{
        color: #FA5555;
        font-size: 20px;
      }
    }
    .work-time-div{
      width: 40%;
      margin-top: 10px;
      text-align: center;
      .div-time{
        color: #3E4576;
        font-size: 16px;
      }
      .div-date{
        margin-top: 10px;
        color: #8B8FAD;
        font-size: 12px;
      }
    }
  }
}
</style>